import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { schedule } from "@ember/runloop";
import { inject as service } from "@ember/service";
import ConditionalLoadingSpinner from "discourse/components/conditional-loading-spinner";
import DButton from "discourse/components/d-button";
import DModal from "discourse/components/d-modal";
import DModalCancel from "discourse/components/d-modal-cancel";
import TextField from "discourse/components/text-field";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import loadScript from "discourse/lib/load-script";
import { arrayToTable, findTableRegex, tokenRange } from "discourse/lib/utilities";
import i18n from "discourse-common/helpers/i18n";
import I18n from "discourse-i18n";
import DTooltip from "float-kit/components/d-tooltip";
export default class SpreadsheetEditor extends Component {
    @service
    dialog;
    @tracked
    showEditReason = false;
    @tracked
    loading = null;
    spreadsheet = null;
    defaultColWidth = 150;
    isEditingTable = !!this.args.model.tableTokens;
    get modalAttributes() {
        if (this.isEditingTable) {
            return {
                title: "table_builder.edit.modal.title",
                insertTable: {
                    title: "table_builder.edit.modal.create",
                    icon: "pencil-alt"
                }
            };
        } else {
            return {
                title: "table_builder.modal.title",
                insertTable: {
                    title: "table_builder.modal.create",
                    icon: "plus"
                }
            };
        }
    }
    @action
    createSpreadsheet(spreadsheet1) {
        this.spreadsheet = spreadsheet1;
        schedule("afterRender", ()=>{
            this.loadLibraries().then(()=>{
                if (this.isEditingTable) {
                    this.buildPopulatedTable(this.args.model.tableTokens);
                } else {
                    this.buildNewTable();
                }
            });
        });
    }
    @action
    showEditReasonField() {
        this.showEditReason = !this.showEditReason;
    }
    @action
    interceptCloseModal() {
        if (this._hasChanges()) {
            this.dialog.yesNoConfirm({
                message: I18n.t("table_builder.modal.confirm_close"),
                didConfirm: ()=>this.args.closeModal()
            });
        } else {
            this.args.closeModal();
        }
    }
    @action
    insertTable() {
        const updatedHeaders1 = this.spreadsheet.getHeaders().split(","); // keys
        const updatedData1 = this.spreadsheet.getData(); // values
        const markdownTable1 = this.buildTableMarkdown(updatedHeaders1, updatedData1);
        if (!this.isEditingTable) {
            this.args.model.toolbarEvent.addText(markdownTable1);
            return this.args.closeModal();
        } else {
            return this.updateTable(markdownTable1);
        }
    }
    _hasChanges() {
        if (this.isEditingTable) {
            const originalSpreadsheetData1 = this.extractTableContent(tokenRange(this.args.model.tableTokens, "tr_open", "tr_close"));
            const currentHeaders1 = this.spreadsheet.getHeaders().split(",");
            const currentRows1 = this.spreadsheet.getData();
            const currentSpreadsheetData1 = currentHeaders1.concat(currentRows1.flat());
            return (JSON.stringify(currentSpreadsheetData1) !== JSON.stringify(originalSpreadsheetData1));
        } else {
            return this.spreadsheet.getData().flat().some((element1)=>element1 !== "");
        }
    }
    loadLibraries() {
        this.loading = true;
        return loadScript("/javascripts/jsuites/jsuites.js").then(()=>{
            return loadScript("/javascripts/jspreadsheet/jspreadsheet.js");
        }).finally(()=>(this.loading = false));
    }
    buildNewTable() {
        const data1 = [
            [
                "",
                "",
                ""
            ],
            [
                "",
                "",
                ""
            ],
            [
                "",
                "",
                ""
            ],
            [
                "",
                "",
                ""
            ],
            [
                "",
                "",
                ""
            ],
            [
                "",
                "",
                ""
            ]
        ];
        const columns1 = [
            {
                title: I18n.t("table_builder.default_header.col_1"),
                width: this.defaultColWidth
            },
            {
                title: I18n.t("table_builder.default_header.col_2"),
                width: this.defaultColWidth
            },
            {
                title: I18n.t("table_builder.default_header.col_3"),
                width: this.defaultColWidth
            },
            {
                title: I18n.t("table_builder.default_header.col_4"),
                width: this.defaultColWidth
            }
        ];
        return this.buildSpreadsheet(data1, columns1);
    }
    extractTableContent(data1) {
        return data1.flat().filter((t1)=>t1.type === "inline").map((t1)=>t1.content);
    }
    buildPopulatedTable(tableTokens1) {
        const contentRows1 = tokenRange(tableTokens1, "tr_open", "tr_close");
        const rows1 = [];
        let headings1;
        const rowWidthFactor1 = 8;
        contentRows1.forEach((row1, index1)=>{
            if (index1 === 0) {
                // headings
                headings1 = this.extractTableContent(row1).map((heading1)=>{
                    return {
                        title: heading1,
                        width: Math.max(heading1.length * rowWidthFactor1, this.defaultColWidth),
                        align: "left"
                    };
                });
            } else {
                // rows:
                rows1.push(this.extractTableContent(row1));
            }
        });
        return this.buildSpreadsheet(rows1, headings1);
    }
    buildSpreadsheet(data1, columns1, opts1 = {}) {
        const postNumber1 = this.args.model?.post_number;
        const exportFileName1 = postNumber1 ? `post-${postNumber1}-table-export` : `post-table-export`;
        // eslint-disable-next-line no-undef
        this.spreadsheet = jspreadsheet(this.spreadsheet, {
            data: data1,
            columns: columns1,
            defaultColAlign: "left",
            wordWrap: true,
            csvFileName: exportFileName1,
            text: this.localeMapping(),
            ...opts1
        });
    }
    buildUpdatedPost(tableIndex1, raw1, newRaw1) {
        const tableToEdit1 = raw1.match(findTableRegex());
        let editedTable1;
        if (tableToEdit1.length) {
            editedTable1 = raw1.replace(tableToEdit1[tableIndex1], newRaw1);
        } else {
            return raw1;
        }
        // replace null characters
        editedTable1 = editedTable1.replace(/\0/g, "\ufffd");
        return editedTable1;
    }
    updateTable(markdownTable1) {
        const tableIndex1 = this.args.model.tableIndex;
        const postId1 = this.args.model.post.id;
        const newRaw1 = markdownTable1;
        const editReason1 = this.editReason || I18n.t("table_builder.edit.default_edit_reason");
        const raw1 = this.args.model.post.raw;
        const newPostRaw1 = this.buildUpdatedPost(tableIndex1, raw1, newRaw1);
        return this.sendTableUpdate(postId1, newPostRaw1, editReason1);
    }
    sendTableUpdate(postId1, raw1, edit_reason1) {
        return ajax(`/posts/${postId1}.json`, {
            type: "PUT",
            data: {
                post: {
                    raw: raw1,
                    edit_reason: edit_reason1
                }
            }
        }).catch(popupAjaxError).finally(()=>{
            this.args.closeModal();
        });
    }
    buildTableMarkdown(headers1, data1) {
        const table1 = [];
        data1.forEach((row1)=>{
            const result1 = {};
            headers1.forEach((_key1, index1)=>{
                const columnKey1 = `col${index1}`;
                return (result1[columnKey1] = row1[index1]);
            });
            table1.push(result1);
        });
        return arrayToTable(table1, headers1);
    }
    localeMapping() {
        return {
            noRecordsFound: prefixedLocale("no_records_found"),
            show: prefixedLocale("show"),
            entries: prefixedLocale("entries"),
            insertANewColumnBefore: prefixedLocale("context_menu.col.before"),
            insertANewColumnAfter: prefixedLocale("context_menu.col.after"),
            deleteSelectedColumns: prefixedLocale("context_menu.col.delete"),
            renameThisColumn: prefixedLocale("context_menu.col.rename"),
            orderAscending: prefixedLocale("context_menu.order.ascending"),
            orderDescending: prefixedLocale("context_menu.order.descending"),
            insertANewRowBefore: prefixedLocale("context_menu.row.before"),
            insertANewRowAfter: prefixedLocale("context_menu.row.after"),
            deleteSelectedRows: prefixedLocale("context_menu.row.delete"),
            copy: prefixedLocale("context_menu.copy"),
            paste: prefixedLocale("context_menu.paste"),
            saveAs: prefixedLocale("context_menu.save"),
            about: prefixedLocale("about"),
            areYouSureToDeleteTheSelectedRows: prefixedLocale("prompts.delete_selected_rows"),
            areYouSureToDeleteTheSelectedColumns: prefixedLocale("prompts.delete_selected_cols"),
            thisActionWillDestroyAnyExistingMergedCellsAreYouSure: prefixedLocale("prompts.will_destroy_merged_cells"),
            thisActionWillClearYourSearchResultsAreYouSure: prefixedLocale("prompts.will_clear_search_results"),
            thereIsAConflictWithAnotherMergedCell: prefixedLocale("prompts.conflict_with_merged_cells"),
            invalidMergeProperties: prefixedLocale("invalid_merge_props"),
            cellAlreadyMerged: prefixedLocale("cells_already_merged"),
            noCellsSelected: prefixedLocale("no_cells_selected")
        };
    }
    static{
        template(`
    <DModal
      @title={{i18n this.modalAttributes.title}}
      @closeModal={{this.interceptCloseModal}}
      class="insert-table-modal"
    >
      <:body>
        <ConditionalLoadingSpinner @condition={{this.loading}}>
          <div
            {{didInsert this.createSpreadsheet}}
            tabindex="1"
            class="jexcel_container"
          ></div>
        </ConditionalLoadingSpinner>
      </:body>

      <:footer>
        <div class="primary-actions">
          <DButton
            @label={{this.modalAttributes.insertTable.title}}
            @icon={{this.modalAttributes.insertTable.icon}}
            @action={{this.insertTable}}
            class="btn-insert-table"
          />

          <DModalCancel @close={{this.interceptCloseModal}} />
        </div>

        <div class="secondary-actions">
          {{#if this.isEditingTable}}
            <div class="edit-reason">
              <DButton
                @icon="info-circle"
                @title="table_builder.edit.modal.trigger_reason"
                @action={{this.showEditReasonField}}
                class="btn-edit-reason"
              />
              {{#if this.showEditReason}}
                <TextField
                  @value={{this.editReason}}
                  @placeholderKey="table_builder.edit.modal.reason"
                />
              {{/if}}
            </div>
          {{/if}}
          <DTooltip
            @icon="question"
            @triggers="click"
            @arrow={{false}}
            class="btn btn-icon no-text"
          >
            <ul>
              <h4>{{i18n "table_builder.modal.help.title"}}</h4>
              <li>
                <kbd>
                  {{i18n "table_builder.modal.help.enter_key"}}
                </kbd>
                {{i18n "table_builder.modal.help.new_row"}}
              </li>
              <li>
                <kbd>
                  {{i18n "table_builder.modal.help.tab_key"}}
                </kbd>
                {{i18n "table_builder.modal.help.new_col"}}
              </li>
              <li>{{i18n "table_builder.modal.help.options"}}</li>
            </ul>
          </DTooltip>
        </div>
      </:footer>
    </DModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
function prefixedLocale(localeString1) {
    return I18n.t(`table_builder.spreadsheet.${localeString1}`);
}
